import $ from 'jquery';
import 'what-input';
import 'picturefill';
import Chartist from 'chartist';
import 'chartist-plugin-legend';
import 'chartist-plugin-tooltips-updated';


// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.Chartist = Chartist;
require('foundation-sites');
require('./lib/jquery.photobox.js');


// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below



$(document).foundation();

$(document).ready(function() {
    

    $("a[rel='external']").attr('target', "_blank");

  $('.gallery.photos').photobox('a',{ time:0 }); 
     
    $("[data-activate]").on("click", function(e){
       
       var $target = "#"+$(this).attr("data-activate");
        
        
       $("body").toggleClass("nav-active");
       
       });


$(window).on('scroll', function (e){

    if ($('body').hasClass("nav-active")){                       
     $('body').removeClass("nav-active");
    }
});  
    

if ( $( "#international-fatalities" ).length ) {    
  // Initialize a Line chart in the container with the ID chart2
  new Chartist.Line('#international-fatalities', {
    labels: [2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018],
    series: [[0.30, 0.26, 0.20, 0.20, 0.20, 0.15, 0.16, 0.12, 0.11, 0.10, 0.09, 0.08, 0.08, 0.08, 0.09, 0.08],
            [0.08, 0.08, 0.08, 0.09, 0.08, 0.06, 0.04, 0.09, 0.04, 0.06, 0.07, 0.06, 0.04, 0.03, 0.04, 0.04],
            [0.06, 0.09, 0.03, 0.09, 0.08, 0.03, 0.04, 0.00, 0.15, 0.05, 0.07, 0.06, 0.10, 0.00, 0.02, 0.00],
            [0.06, 0.02, 0.04, 0.06, 0.04, 0.03, 0.03, 0.01, 0.02, 0.01, 0.02, 0.02, 0.03, 0.01, 0.01, 0.02]
            ]
}, {
    fullWidth: true,
    chartPadding: {
        right: 40
    },
    plugins: [
        Chartist.plugins.legend({
            legendNames: ['South Africa', 'United States', 'Canada', 'Australia'],
            position: 'bottom'
        }),
        Chartist.plugins.tooltip({
        })
    ]
});
}
    
if ( $( "#fatalities-commodity" ).length ) {     
// Initialize a Line chart in the container with the ID chart1
  new Chartist.Line('#fatalities-commodity', {
    labels: [1995,1996,1997,1998,1999,2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018],
    series: [[415,315,277,252,207,173,182,172,149,110,105,114,115,85,81,62,51,53,37,44,33,30,39,40],
            [45,45,53,44,39,46,49,53,58,64,47,40,53,36,41,34,37,28,28,15,22,27,26,12],
            [31,45,40,42,28,31,19,20,23,20,16,20,15,20,18,12,12,11,7,9,5,4,10,9],
            [62,52,27,28,35,35,38,42,40,54,32,26,37,29,27,19,23,20,21,16,17,12,13,20],
            [553,457,397,366,309,309,288,287,270,248,200,200,220,170,167,127,123,112,93,84,77,73,88,81]
     ]
}, {
    fullWidth: true,
    chartPadding: {
        right: 40
    },
    plugins: [
        Chartist.plugins.legend({
            legendNames: ['Gold', 'Platinum', 'Coal', 'Other *', 'All'],
            position: 'bottom'
        }),
        Chartist.plugins.tooltip({
        })
    ]
});
}
 
if ( $( "#fatalities-causes" ).length ) {      
  // Initialize a Line chart in the container with the ID chart2
  new Chartist.Line('#fatalities-causes', {
    labels: [1995,1996,1997,1998,1999,2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018],
    series: [[222, 247, 193, 181, 137, 142, 124, 124, 131, 96, 83, 86, 76, 56, 63, 47, 40, 26, 32, 25, 22, 24, 32, 22],
            [177, 76, 60, 62, 62, 46, 55, 61, 50, 56, 48, 46, 48, 41, 47, 37, 38, 29, 31, 14, 22, 16, 23, 17],
            [10, 9, 15, 14, 11, 12, 17, 14, 13, 15, 9, 15, 19, 4, 8, 3, 5, 8, 3, 6, 5, 2, 5, 2],
            [87, 104, 103, 80, 71, 59, 62, 58, 47, 52, 43, 35, 50, 45, 33, 21, 25, 35, 19, 29, 20, 23, 26, 16],
            [36, 28, 45, 32, 26, 23, 28, 33, 29, 28, 17, 28, 3, 32, 37, 19, 15, 16, 8, 10, 8, 8, 4, 24]
            ]
}, {
    fullWidth: true,
    chartPadding: {
        right: 40
    },
    plugins: [
        Chartist.plugins.legend({
            legendNames: ['Fall of ground', 'Transport and mining', 'Machinery', 'General', 'Other'],
            position: 'bottom'
        }),
        Chartist.plugins.tooltip({
        })
    ]
});
}
    
     
    

$('[data-video-id]').on('click', function() {
        
       var $target = "#"+$(this).attr('data-open');
        $($target).find('iframe').attr('src', 'https://www.youtube.com/embed/'+$(this).attr('data-video-id')+'?autoplay=1&rel=0');
    });  
      
$('#videoModal').on({'closed.zf.reveal': function(){
    $(this).find('iframe').attr('src','');
}}); 
    
    
    
 });